import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 70,
  },
  video: {
    height: 195,
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    paddingLeft: 13,
    color: "#A7A9AC",
    display: "inline-block",
    textTransform: "uppercase",
  },
  titleDiv: {
    "&::before": {
      content: "''",
      width: 3,
      height: 20,
      display: "inline-block",
      position: "absolute",
      background: "#89858A",
    },
  },
}));

export default function Video(props) {
  const classes = useStyles();
  const {video} = props;
  let {lang} = useParams();

  return (
    <Grid container direction="row" justifyContent="center" className={classes.root}>
      <Grid item xs={11}>
        <Link to={`/${lang}/category/video/${video.slug}`}>
          <div className={classes.video} style={{background: "url(" + video.image + ") no-repeat center"}}>
          </div>
        </Link>
        <div className={classes.titleDiv}>
          <Link className={classes.title} to={`/${lang}/category/video/${video.slug}`}>
            {video.title}
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}
