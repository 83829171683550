import React from "react";
import { InputBase } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  formGroup: {
    "& label": {
      maxWidth: "100%",
      marginBottom: 5,
      fontWeight: 700,
      color: "#565a5c",
    },
    "& input": {
      fontSize: 12,
      color: "#333",
      borderRadius: 3,
      padding: "0 9px",
    },
    "& textarea": {
      padding: "0 9px",
    },
  },
  input: {
    outline: "none",
    border: "1px solid #ccc",
    fontFamily: "'MoshVerdana' !important",
  },
})

export default function ContactsInput({name, label, placeholder = label, multiline=false, xs=6}) {
  const classes = useStyles();

  return (
    <Grid item xs={xs} className={classes.formGroup}>
      <label htmlFor={name}>{label}</label>
      <InputBase
        required
        multiline={multiline}
        fullWidth
        rows="10"
        name={name}
        className={classes.input}
        style={{ height: multiline ? "auto" : 28 }}
        notched="false"
        placeholder={placeholder}
      />
    </Grid>
  );
}
