import React from "react";
import Languages from "./Languages";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  header: {
    position: "absolute",
    padding: 0,
    zIndex: 2,
  },
}));

export default function Header({menu}) {
  const classes = useStyles();

  return (
    <Container className={classes.header} maxWidth={false}>
      <Languages menu={menu}/>
      <Logo/>
      <NavMenu menu={menu}/>
    </Container>
  );
}
