import React, { useState } from "react";
import InputBase from '@mui/material/InputBase';
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {styled} from "@mui/material/styles";
import {alpha} from "@mui/material";

const SearchBlock = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Search() {
  const {t} = useTranslation('common');
  const [searchQuery, setSearchQuery] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {pathname} = useLocation();
  const lang = pathname.substring(1, 3);

  function handleChange(e) {
    setSearchQuery(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (searchQuery.length > 0) {
      setIsSubmitted(true);
    }
  }

  return (
    <>
      {
        searchQuery.length > 0 && isSubmitted &&
        <Redirect to={`/${lang}/search?q=${searchQuery}`}/>
      }
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <SearchBlock>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={t("search")}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'search' }}
          />
        </SearchBlock>
      </form>
    </>
  );
}
