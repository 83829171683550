import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 70,
  },
  title: {
    fontSize: 23,
    paddingLeft: 13,
    color: "#A7A9AC",
    display: "inline-block",
    textTransform: "uppercase",
    "& p": {
      margin: 0,
    }
  },
  image: {
    width: "100%",
  },
  titleDiv: {
    "&::before": {
      content: "''",
      width: 3,
      height: 20,
      display: "inline-block",
      position: "absolute",
      background: "#89858A",
    },
  },
}));

export default function Service({service}) {
  const classes = useStyles();
  let {lang} = useParams();

  return (
    <Grid container direction="row" justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.titleDiv}>
          <Link className={classes.title} to={`/${lang}/category/projects/${service.slug}`}>
            <p>{service.title}</p>
          </Link>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Link className={classes.title} to={`/${lang}/category/projects/${service.slug}`}>
          <img alt="" src={service.image} className={classes.image}/>
        </Link>
      </Grid>
    </Grid>
  );
}
