import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import loadingImage from "images/loading2.gif";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  }
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={2}>
          <img alt="" src={loadingImage}/>
        </Grid>
      </Grid>
    </Container>
  );
}
