import React from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px 0 0 0",
  },
  secondTitle: {
    textDecoration: "none",
    fontSize: 14,
    color: "#000",
    fontWeight: 700,
  },
}));

export default function Author(props) {
  const classes = useStyles();
  const {author} = props;
  let { lang } = useParams();

  if (!author) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Link className={classes.secondTitle} to={`/${lang}/author/` + author.slug}>{author.name}</Link>
    </div>
  );
}
