import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ContactsInput from "./ContactsInput";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 70,
  },
  form: {
    borderLeft: "1px solid #ddd",
    marginTop: 5,
  },
  formsBlock: {
    padding: "20px 0 20px 31px",
  },
  title: {
    "& h1": {
      marginBottom: 0,
    },
    "& span": {
      color: "#999",
      margin: 0,
      padding: 0,
      fontSize: 12,
      lineHeight: "14px",
    }
  },
  containedPrimary: {
      backgroundColor: "#51bce6",
  },
}));

export default function Contacts() {
  const classes = useStyles();
  const {t} = useTranslation('common');

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
        <Grid item xs={11} md={8}>
          <div className={classes.title}>
            <h1>{t("contacts_title")}</h1>
            <span>{t("contact_information")}</span>
          </div>
          <form noValidate autoComplete="off" className={classes.form}>
            <div className={classes.formsBlock}>
              <Grid container spacing={6} direction="row" justifyContent="flex-start">
                <ContactsInput name="name" label={t("contact_firstname")} placeholder={t("contact_firstname_placeholder")}/>
                <ContactsInput name="surname" label={t("contact_surname")} placeholder={t("contact_surname_placeholder")}/>
                <ContactsInput name="email" label={t("contact_email")} placeholder={t("contact_email_placeholder")}/>
                <ContactsInput name="phone" label={t("contact_phone")} placeholder={t("contact_phone_placeholder")}/>
                <ContactsInput name="message" label={t("contact_message")} placeholder={t("contact_message_placeholder")} multiline xs={12}/>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" classes={{containedPrimary: classes.containedPrimary}}>
                    {t("contact_send")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
