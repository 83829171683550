import { createTheme } from "@mui/material/styles";

const newTheme = createTheme({
  palette: {
    primary: {
      main: "#405662",
    },
    secondary: {
      main: "#FAAE4C",
    },
  },
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1170,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      "MoshVerdana",
      "Arian-AMU",
    ].join(','),
  },
});

export default newTheme;
