import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";
import Author from "Components/Author/Author";
import {getFormatImage} from "../../../helpers";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "70px",
  },
  pdf: {
    boxShadow: "0 0 7px 0px #0000008c",
  },
  title: {
    fontWeight: 700,
    marginBottom: 5,
    fontSize: 15,
    textDecoration: "none",
    color: "#89858A",
    textTransform: "uppercase",
  },
  description: {
    marginBottom: 0,
    fontSize: 14,
    color: "#A7A9AC",
  }
}));

export default function Article(props) {
  const classes = useStyles();
  let { lang } = useParams();
  const {article} = props;
  const bgImage = getFormatImage(article.format);

  return (
    <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
      <Grid item xs={2}>
        <img alt="" className={classes.pdf} src={bgImage} />
      </Grid>
      <Grid item xs={10}>
        <div style={{paddingLeft: 5}}>
          <div>
            <Link className={classes.title} to={`/${lang}/category/articles/${article.slug}`}>{article.title}</Link>
          </div>
          <Author author={article.author}/>
          <div>
            <p className={classes.description}>{article.short_description}</p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
