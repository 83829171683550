import React from "react";
import Grid from "@mui/material/Grid";
import {Link, useLocation} from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import LogoImage from "../../images/logo.png";
import {detectLanguage} from "../../helpers";

const useStyles = makeStyles(() => ({
  logoHome: {
    margin: "34px 0 7px 12px"
  },
  logoLink: {
    textDecoration: "none",
  },
  logoImage: {
    width: "100%",
  },
  logoText: {
    fontSize: 16,
    color: "#000",
    fontFamily: "Arian-AMU",
    textTransform: "uppercase",
    margin: "-3px 0 0 0",
    paddingLeft: 10,
    letterSpacing: 3.3,
  },
}));

export default function Logo() {
  const {pathname} = useLocation();
  const lang = detectLanguage(pathname);
  const classes = useStyles();

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <div className={classes.logoHome}>
            <Link className={classes.logoLink} to={"/" + lang}>
              <img className={classes.logoImage} alt="" src={LogoImage}/>
              <p className={classes.logoText}>Armenian Research Center in Humanities</p>
            </Link>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
