import React from "react";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
  notFound: {
    textAlign: "center",
    width: "100%",
    fontSize: 45,
  }
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <div>
      <h1 className={classes.notFound}>404 Not Found!</h1>
    </div>
  );
}
