import React, { useEffect, useState } from "react";
import { Parallax, Background } from 'react-parallax';
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  rightArrow: {
    cursor: "pointer",
    position: 'absolute',
    top: '50%',
    right: 20,
    backgroundColor: "#405662",
    display: "grid",
    color: "#fff",
    opacity:0,
    transition: "visibility 0.3s linear,opacity 0.3s linear",
    '& > svg': {
      fontSize: '2rem',
    }
  },
  leftArrow: {
    cursor: "pointer",
    position: 'absolute',
    top: '50%',
    left: 20,
    backgroundColor: "#405662",
    display: "grid",
    color: "#fff",
    opacity:0,
    transition: "visibility 0.3s linear,opacity 0.3s linear",
    '& > svg': {
      fontSize: '2rem',
    }
  },
  parallax: {
    height: 545,
  },
  parallaxWithArrows: {
    "& :hover div div": {
      opacity: 1,
    }
  },
  image: {
    transitionDuration: '0.5s',
    transition: "visibility 0.5s linear,opacity 0.5s linear",
    position: "relative",
  }
}));

export default function Slider({banners}) {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateCurrent = (newIndex) => {
    if (newIndex < 0) {
      newIndex = banners.length - 1;
    } else if (newIndex >= banners.length) {
      newIndex = 0;
    }

    setCurrent(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateCurrent(current + 1);
      }
    }, 8000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });


  if (banners.length === 0) {
    return '';
  }
  return (
    <Parallax
      className={clsx(
          classes.parallax, {
            [classes.parallaxWithArrows]: banners.length > 1
          }
      )}
      strength={200}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <Background className="custom-bg">
        <div style={{
          height: "100%",
          width: "100%",
        }}
        />
        {
          banners.map((item, index) => (
            <img src={item.image} key={index}
                 alt={item.description}
                 className={clsx(classes.image, {
                   'hidden-image': current !== index,
                   'active-image': current === index,
                 })}
            />
          ))
        }
        <div className={classes.leftArrow} onClick={() => { updateCurrent(current - 1) }}>
          <ChevronLeftIcon/>
        </div>
        <div className={classes.rightArrow} onClick={() => { updateCurrent(current + 1) }}>
          <ChevronRightIcon/>
        </div>
      </Background>
    </Parallax>
  );
}
