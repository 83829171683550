import React from "react";
import Grid from "@mui/material/Grid";
import Book from "./Book";
import Loader from "Components/Partials/Loader";
import { setPageTitle } from "../../../helpers";

export default function BooksGroup({ isLoaded, books }) {
  setPageTitle();
  if (!isLoaded) {
    return (<Loader/>);
  }

  return (
    <>
      {
        books.map(book => {
          return (
            <Grid item key={book.id} md={3} sm={12} xs={12}>
              <Book book={book}/>
            </Grid>
          );
        })
      }
    </>
  );
}
