import React from "react";
import Grid from "@mui/material/Grid";
import Article from "./Article";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "70px",
  },
  item: {
    padding: 10,
  },
}));

export default function ArticlesGroup({isLoaded, articles}) {
  const classes = useStyles();

  if (!isLoaded) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" className={classes.root}>
      {
        articles.map(article => (
          <Grid key={article.id} item md={4} sm={10} className={classes.item}>
            <Article article={article}/>
          </Grid>
        ))
      }
    </Grid>
  );
}
