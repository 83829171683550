import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import ServicesGroup from "../Projects/ServicesGroup";
import { Ajax } from "../../../helpers";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  }
}));

export default function NewsPage() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [news, setNews] = useState([]);
  let {lang} = useParams();
  const {t} = useTranslation('common');

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/api/news", lang)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setNews(result.news);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [lang])

  return (
    <Container>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.readMore} container justifyContent="space-between">
            <Grid item>
              <h2 className={classes.subheader}>{t("news")}</h2>
            </Grid>
          </Grid>
        </Grid>

        <ServicesGroup error={error} isLoaded={isLoaded} services={news}/>
      </Grid>
    </Container>
  );
}
