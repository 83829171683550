import React, { useEffect, useState } from "react";
import 'App.css';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import newTheme from "Components/newTheme";
import Main from "Layout/Main";
import Footer from "Components/Footer/Footer";
import {Route, Switch, Redirect, useLocation} from "react-router-dom";
import ShowArticle from "Components/Resources/Articles/ShowArticle";
import ShowBook from "Components/Resources/Books/ShowBook";
import AuthorsPage from "Components/Author/AuthorsPage";
import ShowVideo from "Components/Resources/Videos/ShowVideo";
import WithFilters from "Layout/WithFilters";
import Header from "Components/Header/Header";
import ShowPage from "Components/Page/ShowPage";
import ServicesPage from "Components/Resources/Projects/ServicesPage";
import ShowService from "Components/Resources/Projects/ShowService";
import Contacts from "Components/Page/Contacts/Contacts";
import i18n from "i18next";
import { I18nextProvider, useTranslation } from "react-i18next";
import SearchPage from "Components/Page/SearchPage";
import common_am from "locales/am/translation.json";
import common_en from "locales/en/translation.json";
import common_ru from "locales/ru/translation.json";
import Archive from "Components/Timeline/Archive";
import NewsPage from "Components/Resources/News/NewsPage";
import GalleriesPage from "Components/Gallery/GalleriesPage";
import ShowGallery from "Components/Gallery/ShowGallery";
import ShowTimelineAbout from "Components/Timeline/ShowTimelineAbout";
import ShowTimelinePublicity from "Components/Timeline/ShowTimelinePublicity";
import ShowMedia from "Components/Timeline/ShowMedia";
import {Ajax, detectLanguage} from "./helpers";
import ShowTimelineDocuments from "./Components/Timeline/ShowTimelineDocuments";
import AboutUs from "./Components/Page/AboutUs/AboutUs";
import NotFound from "./Components/Errors/NotFound";

const useStyles = makeStyles(() => ({
  article: {
    padding: "225px 0 0 0",
    minHeight: 700,
    color: "#7c7c7f",
  }
}));

i18n.init({
  interpolation: {escapeValue: false},  // React already does escaping
  lng: 'am',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    am: {
      common: common_am
    },
    ru: {
      common: common_ru
    },
  },
  react: {
    useSuspense: true,
  },
});

function App() {
  const classes = useStyles();
  const {pathname} = useLocation();
  const lang = detectLanguage(pathname);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/api/menu", lang)
      .then(res => res.json())
      .then((result) => {
          setMenu(result.menu);
        }
      );

    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang])

  const {ready} = useTranslation('common', {i18n});

  if (!ready) {
    return null;
  }

  if (!lang) {
    return (
        <Redirect to={'/am' + pathname}/>
    )
  }

  return (
    <ThemeProvider theme={newTheme}>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
        <CssBaseline/>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/am"/>
          </Route>
          <Route path="/">
            <Header menu={menu}/>
            <Switch>
              <Route path="/:lang" exact component={Main}/>
              <Route path="/:lang">
                <Container className={classes.article}>
                  <Switch>
                    <Route path="/:lang/page/contacts" exact component={Contacts}/>
                    <Route path="/:lang/page/about-us" exact component={AboutUs}/>
                    <Route path="/:lang/page/:slug" component={ShowPage}/>
                    <Route path="/:lang/gallery" exact component={GalleriesPage}/>
                    <Route path="/:lang/gallery/:slug" exact component={ShowGallery}/>
                    <Route path="/:lang/category/articles/:slug" component={ShowArticle}/>
                    <Route path="/:lang/category/books/:slug" component={ShowBook}/>
                    <Route path="/:lang/news" component={NewsPage}/>
                    <Route path="/:lang/category/video/:slug" component={ShowVideo}/>
                    <Route path="/:lang/category/projects" exact component={ServicesPage}/>
                    <Route path="/:lang/category/projects/:slug" exact component={ShowService}/>
                    <Route path="/:lang/category/:slug" exact component={WithFilters}/>
                    <Route path="/:lang/author/:slug" component={AuthorsPage}/>
                    <Route path="/:lang/archive" exact component={Archive}/>
                    <Route path="/:lang/archive/:slug/about" exact component={ShowTimelineAbout}/>
                    <Route path="/:lang/archive/:slug/publicity" exact component={ShowTimelinePublicity}/>
                    <Route path="/:lang/archive/:slug/documents" exact component={ShowTimelineDocuments}/>
                    <Route path="/:lang/archive/:slug/media" exact component={ShowMedia}/>
                    <Route path="/:lang/search" component={SearchPage}/>
                    <Route path="*">
                      <NotFound/>
                    </Route>
                  </Switch>
                </Container>
              </Route>
            </Switch>
            <Footer menu={menu}/>
          </Route>
        </Switch>
        </StyledEngineProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
