import React from "react";
import Grid from "@mui/material/Grid";
import Service from "./Service";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  projectItem: {
    overflow: "hidden",
  }
}));

export default function ServicesGroup({ isLoaded, services }) {
  const classes = useStyles();

  if (!isLoaded || services === undefined) {
    return (
      <></>
    );
  }

  return (
    <>
      {
        services.map(service => (
          <Grid key={service.id} item xs={12} className={classes.projectItem}>
            <Service service={service}/>
          </Grid>
        ))
      }
    </>
  );
}
