import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  error: {
    textAlign: "center",
    width: "100%",
    fontSize: 45,
  }
}));

export default function Error() {
  const classes = useStyles();

  return (
    <div>
      <h1 className={classes.error}>ERROR!</h1>
    </div>
  );
}
