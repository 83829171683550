import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import {makeStyles, useTheme} from "@mui/styles";
import { Ajax, setPageTitle } from "helpers";
import Loader from "../../Partials/Loader";
import bgPdf from "images/bg_pdf.png";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import NotFound from "../../Errors/NotFound";
import Error from "../../Errors/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  },
  contentBody: {
    textAlign: "justify",
  },
  image: {
    width: "100%",
    boxShadow: "0 0 7px 2px #00000038",
  },
  author: {
    display: "inline-block",
    width: "100%",
    fontSize: 16,
    marginTop: 9,
    position: "relative",
    borderLeft: "2px solid #ccc",
    paddingLeft: 0,
    "& a": {
      display: "inline-block",
      width: "100%",
      fontSize: 14,
      fontFamily: "MoshVerdana_Bold",
      color: "#000",
      marginTop: 6,
      paddingLeft: 9,
    }
  },
  mainContent: {
    padding: "0 15px 0 15px",
    "& > p": {
      display: "inline-block",
      color: "#565a5c",
      width: "100%",
      fontSize: 18,
      textTransform: "uppercase",
      margin: "0 0 10px 0",
    },
    [theme.breakpoints.up('md')]: {
      "& > div": {
        display: "inline-block",
        width: "100%",
        marginBottom: 35,
        paddingLeft: 50,
        boxSizing: "border-box",
      },
    }
  },
  description: {
    display: "inline-block",
    width: "100%",
    fontSize: 15,
    fontFamily: "'MoshVerdana' !important",
    color: "#000",
  },
  littleImage: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  fileLinks: {
    padding: "30px 10px",
    "& a": {
      display: "inline-block",
      fontWeight: 700,
      marginBottom: 5,
      fontSize: 15,
      color: "#89858A",
      textTransform: "uppercase",
      "& > a": {
        paddingLeft: 10,
      },
    }
  },
  links: {
    borderTop: "2px dotted #BBB8BC",
    "& > a": {
      color: "#565a5c",
    }
  }
}));

export default function ShowBook() {
  let { slug, lang } = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [book, setBook] = useState([]);
  const {t} = useTranslation('common');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function shrinkText(text) {
    if (!isMobile) {
      return text;
    }
    return text.substring(0, 21) + ".pdf";
  }

  useEffect(() => {
    setIsLoaded(false);
    Ajax(process.env.REACT_APP_BASE_URL + "/api/books/" + slug, lang)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setError(<NotFound/>);
          setIsLoaded(true);
        } else {
          setError(<Error/>);
          setIsLoaded(true);
        }
      })
      .then(result => {
        if (result === undefined) {
          return;
        }
        setBook(result);
        setPageTitle(result.title);
        setIsLoaded(true);
      })
  }, [slug, lang])

  if (!isLoaded) {
    return (<Loader/>);
  }

  if (error) {
    return error;
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.readMore} container justifyContent="space-between">
            <Grid item>
              <Link to={`/${lang}/category/books`}>
                <h2 className={classes.subheader}>{t('books')}</h2>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid className={classes.contentBody} container justifyContent="center" alignItems="flex-start">
            <Grid item md={3} xs={12}>
              <img alt="" src={book.image_link} className={classes.image}/>
            </Grid>
            <Grid item md={7} xs={12} className={classes.mainContent}>
              <p>{book.title}</p>
              <div>
                <p>{book.short_description}</p>
              </div>
              <div className={classes.description} dangerouslySetInnerHTML={{__html: book.body}}/>
            </Grid>
            <Grid item md={2} xs={12} className={classes.author}>
              {
                book.authors.map(author => {
                  return (<Link to={`/${lang}/author/${author.slug}`} key={author.id}>{author.name}</Link>);
                })
              }
            </Grid>
            <Grid item xs={12} className={classes.fileLinks}>
              {
                book.downloadable_files.map(file => {
                  return (
                    <a key={file.download_link} target="_blank" rel="noreferrer" href={file.download_link}>
                      <img alt="" className={classes.littleImage} src={bgPdf}/>
                      <span>{shrinkText(file.original_name)}</span>
                    </a>
                  );
                })
              }
            </Grid>
            {book.link !== undefined && book.link !== '' && (
              <Grid item xs={12} className={classes.links}>
                <a href={book.link}>{ book.link }</a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
