import {useLocation} from "react-router-dom";
import React from "react";
import bgPdf from "./images/bg_pdf.png";
import bgDoc from "./images/bg_doc.png";
import bgFont from "./images/bg_font.png";

export function Ajax(url, lang) {

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang === undefined ? 'en' : lang,
      'Accept': 'application/json'
    }
  });
}

export function setPageTitle(title = null) {
  if (title === null || title === '' || title === undefined) {
    title = 'Armenian Research Center in Humanities';
  }

  document.title = title;
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getFormatImage(format) {
  switch (format) {
    case 'pdf':
      return bgPdf;
    case 'doc':
      return bgDoc;
    default:
      return bgFont;
  }
}

export function isValidUrl(string) {

  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export function detectLanguage(pathname) {
  const lang = pathname.substring(1, 3);
  switch (lang) {
    case 'am':
      return lang;
    case 'ru':
      return lang;
    case 'en':
      return lang;
    default:
      return false;
  }
}
