import React from "react";
import Grid from "@mui/material/Grid";
import Video from "./Video";

export default function VideosGroup({ isLoaded, videos }) {
  if (!isLoaded || videos === undefined) {
    return (
      <></>
    );
  }

  return (
    <>
      {
        videos.map(video => (
          <Grid key={video.id} item md={4} sm={12} xs={12}>
            <Video video={video}/>
          </Grid>
        ))
      }
    </>
  );
}
