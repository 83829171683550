import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import bgPdf from "images/bg_pdf.png";
import bgDoc from "images/bg_doc.png";
import bgFont from "images/bg_font.png";
import { Ajax, setPageTitle } from "helpers";
import Loader from "../../Partials/Loader";
import NotFound from "../../Errors/NotFound";
import Error from "../../Errors/Error";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  },
  contentBody: {
    textAlign: "justify",
  },
  formatImage: {
    boxShadow: "0 0 7px 0px #0000008c",
  },
  author: {
    display: "inline-block",
    width: "100%",
    fontSize: 16,
    marginTop: 9,
    position: "relative",
    borderLeft: "2px solid #ccc",
    paddingLeft: 0,
    "& a": {
      display: "inline-block",
      width: "100%",
      fontSize: 14,
      fontFamily: "MoshVerdana_Bold",
      color: "#000",
      marginTop: 6,
      paddingLeft: 9,
    }
  },
  mainContent: {
    paddingRight: 15,
    "& > p": {
      display: "inline-block",
      color: "#565a5c",
      width: "100%",
      fontSize: 18,
      textTransform: "uppercase",
      margin: "0 0 10px 0",
    },
    "& > div": {
      display: "inline-block",
      width: "100%",
      marginBottom: 35,
      paddingLeft: 50,
      boxSizing: "border-box",
    }
  },
  description: {
    display: "inline-block",
    width: "100%",
    fontSize: 15,
    fontFamily: "'MoshVerdana' !important",
    color: "#000",
  },
  littleImage: {
    height: 20,
    width: 20,
    marginRight: 10,
  },

  fileLink: {
    display: "inline-block",
    fontWeight: 700,
    marginBottom: 5,
    fontSize: 15,
    color: "#89858A",
    textTransform: "uppercase",
    "& > a": {
      paddingLeft: 10,
    },
  },
  links: {
    borderTop: "2px dotted #BBB8BC",
    paddingTop: 30,
    "& > a": {
      color: "#565a5c",
    }
  }
}));

function getFormatImage(format) {
  switch (format) {
    case 'pdf':
      return bgPdf;
    case 'doc':
      return bgDoc;
    default:
      return bgFont;
  }
}

export default function ShowArticle() {
  let {slug, lang} = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [article, setArticle] = useState([]);

  useEffect(() => {
    setIsLoaded(false);
    Ajax(process.env.REACT_APP_BASE_URL + "/api/articles/" + slug, lang)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setError(<NotFound/>);
          setIsLoaded(true);
        } else {
          setError(<Error/>);
          setIsLoaded(true);
        }
      })
      .then(
        (result) => {
          if (result === undefined) {
            return;
          }
          setPageTitle(result.title)
          setArticle(result);
          setIsLoaded(true);
        }
      )
  }, [slug, lang])

  if (!isLoaded) {
    return (<Loader/>);
  }

  if (error) {
    return error;
  }

  const formatImage = getFormatImage(article.format);

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.readMore} container justifyContent="space-between">
            <Grid item>
              <Link to={`/${lang}/category/articles`}>
                <h2 className={classes.subheader}>articles</h2>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid className={classes.contentBody} container justifyContent="center" alignItems="flex-start">
            <Grid item xs={1}>
              <img alt="" src={formatImage} className={classes.formatImage}/>
            </Grid>
            <Grid item xs={9} className={classes.mainContent}>
              <p>{article.title}</p>
              <div>
                <p>{article.short_description}</p>
              </div>
              <div className={classes.description} dangerouslySetInnerHTML={{__html: article.body}}/>
            </Grid>
            <Grid item xs={2} className={classes.author}>
              {
                article.authors.map(author => {
                  return (<Link to={`/${lang}/author/${author.slug}`} key={author.id}>{author.name}</Link>);
                })
              }
            </Grid>
            <Grid item xs={12}>
              {
                article.downloadable_files.map(file => {
                  return (
                    <a className={classes.fileLink} key={file.download_link} target="_blank" rel="noreferrer"
                       href={file.download_link}>
                      <img alt="" className={classes.littleImage} src={formatImage}/>
                      <span>{file.original_name}</span>
                    </a>
                  );
                })
              }
            </Grid>
            {article.link !== undefined && article.link !== '' && (
              <Grid item xs={12} className={classes.links}>
                <a href={article.link}>{ article.link }</a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
