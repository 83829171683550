import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";
import Author from "Components/Author/Author";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "70px",
  },
  image: {
    width: "100%",
    boxShadow: "0 0 7px 0px #0000008c",
  },
  title: {
    display: "inline-block",
    width: "100%",
    color: "#A7A9AC",
    fontWeight: 700,
    fontSize: 15,
    paddingLeft: 15,
    textTransform: "uppercase",
  },
  titleDiv: {
    "&::before": {
      content: "''",
      width: 3,
      height: 45,
      display: "inline-block",
      position: "absolute",
      background: "#89858A",
    },
  },
  author: {
    paddingLeft: 15,
  },
}));

export default function Book(props) {
  const classes = useStyles();
  const { book } = props;
  let { lang } = useParams();

  return (
    <Grid container direction="row" justifyContent="center" className={classes.root}>
      <Grid item xs={8}>
        <Link to={`/${lang}/category/books/${book.slug}`}>
          <img alt="" className={classes.image} src={book.image_link} />
        </Link>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.titleDiv}>
          <Link className={classes.title} to={`/${lang}/category/books/${book.slug}`}>{book.title}</Link>
        </div>
      </Grid>
      <Grid item xs={8} className={classes.author}>
        <Author author={book.author}/>
      </Grid>
    </Grid>
  );
}
