import React from "react";

export default function YoutubeEmbed(props) {
  const {title} = props;
  let url = props.url;

  // if full url is provided, extract the video id
  if (url.includes("watch?v=")) {
    url = url.replace("watch?v=", "embed/");
  }
  // remove any extra parameters
  url = url.split("&")[0];

  return (
    <div className="video-responsive">
      <iframe
        src={url}
        width="100%"
        height="530"
        frameBorder="0"
        allow="encrypted-media"
        allowFullScreen
        title={title}
      />
    </div>
  );
}
