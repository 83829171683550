import React from 'react';
import { makeStyles } from '@mui/styles';
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FooterLogo from "../../images/footer/arc_logo.png";
import LogoKAS from "../../images/footer/kas_logo.png";
import Hidden from "@mui/material/Hidden";

const useStyles = makeStyles({
  root: {
    color: "#000000",
    padding: "0 15px 0 15px",
  },
  menu: {
    display: "inline-block",
    width: "100%",
    float: "left",
    paddingLeft: 15,
    marginTop: 15,
    paddingBottom: 0,
  },
  link: {
    display: "inline-block",
    float: "left",
    position: "relative",
    width: 180,
  },
  menuItem: {
    display: "inline-block",
    fontSize: 13,
    marginRight: 66,
    color: "#7c7c7f",
    textTransform: "uppercase",
  },
  content: {
    display: "inline-block",
    boxSizing: "border-box",
    padding: "20px 0px 12px",
  },
  footerContact: {},
  footerLogo: {
    display: "inline-block",
    height: 84,
  },
  copyright: {
    display: "inline-block",
    color: "#7c7c7f",
    fontFamily: "MoshVerdana",
    fontSize: 10,
  },
  support: {
    display: "inline-block",
    marginTop: 0,
    // width: 120,
    "& > small": {
      display: "inline-block",
      float: "left",
      width: 100,
      marginBottom: -40,
      position: 'relative',
      top: 16,
      color: "#7c7c7f",
    }
  },
  logoKAS: {
    "& > img": {
      width: 200,
      marginLeft: -40
    }
  },
  submenu: {
    paddingLeft: 10,
  }
});

export default function Footer({menu}) {
  const classes = useStyles();
  const {pathname} = useLocation();
  const lang = pathname.substring(1, 3);

  function menuItem(item) {
    if (item.children.length === 0) {
      return (
        <li className={classes.link} key={item.id}>
          <Link to={`/${lang}/${item.url}`} className={classes.menuItem}>{item.title}</Link>
        </li>
      )
    }

    return (
      <li className={classes.link} key={item.id}>
        <span className={classes.menuItem}>{item.title}</span>
        <ul className={classes.submenu}>
          {
            item.children.map(child => {
              return (
                <li className={classes.link} key={child.id}>
                  <Link key={child.id} className={classes.menuItem} to={`/${lang}/${child.url}`}>{child.title}</Link>
                </li>
              )
            })
          }
        </ul>
      </li>
    )
  }

  return (
    <Container>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.root}>
        <Hidden only={['sm', 'xs']}>
          <Grid item className={classes.content} xs={12}>
            <ul className={classes.menu}>
              {
                menu.map(item => (
                  menuItem(item)
                ))
              }
            </ul>
          </Grid>
        </Hidden>
        <Grid item md={4} sm={4} xs={12} className={classes.footerContact}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
            <Grid item sm={2}>
              <img alt="" src={FooterLogo} className={classes.footerLogo}/>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={4}>
            <p className={classes.copyright}>Armenian Research Center in Humanities © all rights reserved</p>
          </Grid>
        </Hidden>
        <Grid item md={4} sm={4} xs={12} style={{textAlign: "left"}}>
          <div className={classes.support}>
            <small>support by</small>
            <a href="https://www.kas.de/" className={classes.logoKAS}>
              <img alt="" src={LogoKAS}/>
            </a>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
