import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";
import ArticlesGroup from "Components/Resources/Articles/ArticlesGroup";
import BooksGroup from "Components/Resources/Books/BooksGroup";
import VideosGroup from "Components/Resources/Videos/VideosGroup";
import { Ajax } from "helpers";
import PageBlock from "Components/Page/PageBlock";
import { useTranslation } from "react-i18next";
import Slider from "Components/Slider/Slider";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
  },
  youtubeIcon: {
    color: "#b34d26",
    backgroundColor: "#fff",
    fontSize: 38,
    margin: "10px",
  },
  facebookIcon: {
    color: "#b34d26",
    backgroundColor: "#fff",
    fontSize: 28,
    margin: "10px",
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 20,
  },
  moreLink: {
    fontSize: 17,
    textDecoration: "none",
    color: "#C1BEC2",
    "& > span": {
      paddingRight: 77,
    },
    "& > svg": {
      verticalAlign: "middle",
      fontSize: 40,
    },
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  }
}));

export default function Main() {
  const classes = useStyles();
  let {lang} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [articles, setArticles] = useState([]);
  const [books, setBooks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState(null);
  const {t} = useTranslation('common');

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/api/home", lang)
      .then(res => res.json())
      .then((result) => {
          setIsLoaded(true);
          setArticles(result.articles);
          setBooks(result.books);
          setBanners(result.banners);
          setPage(result.page);
          setVideos(result.video);
        }
      )
  }, [lang])

  return (
    <>
      <Container className={classes.root} id="main">
        <Slider banners={banners}/>
      </Container>
      <Container>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <a href="https://www.facebook.com/ArmenianResearchCenterinHumanities/" rel="noreferrer" target="_blank">
            <FacebookIcon className={classes.facebookIcon}/>
          </a>
          <a href="https://www.youtube.com/channel/UCHU_l5r9bUqhg-IJcCnXbxw?view_as=subscriber" rel="noreferrer"
             target="_blank">
            <YouTubeIcon className={classes.youtubeIcon}/>
          </a>
          <PageBlock lang={lang} page={page}/>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <Grid className={classes.readMore} container justifyContent="space-between">
              <Grid item>
                <h2 className={classes.subheader}>{t("articles")}</h2>
              </Grid>
              <Grid item>
                <Link className={classes.moreLink} to={`/${lang}/category/articles`}>
                  <span>{t("more")}</span>
                  <ArrowRightIcon/>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <ArticlesGroup isLoaded={isLoaded} articles={articles}/>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <Grid className={classes.readMore} container justifyContent="space-between">
              <Grid item>
                <h2 className={classes.subheader}>{t("books")}</h2>
              </Grid>
              <Grid item>
                <Link className={classes.moreLink} to={`/${lang}/category/books`}>
                  <span>{t("more")}</span>
                  <ArrowRightIcon/>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <BooksGroup isLoaded={isLoaded} books={books}/>
        </Grid>

        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12}>
            <Grid className={classes.readMore} container justifyContent="space-between">
              <Grid item xs={5}>
                <h2 className={classes.subheader}>{t("video_gallery")}</h2>
              </Grid>
              <Grid item>
                <Link className={classes.moreLink} to={`/${lang}/category/video`}>
                  <span>{t("more")}</span>
                  <ArrowRightIcon/>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <VideosGroup isLoaded={isLoaded} videos={videos}/>
        </Grid>
      </Container>
    </>
  );
}
