import React from "react";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  filters: {
    paddingBottom: "40px",
    "& div": {
      float: "left",
    }
  },
  searchYear: {
    minWidth: 130,
    border: "1px #000 solid",
    paddingLeft: 10,
  },
  filterLabel: {
    paddingBottom: 20,
    color: "#000",
    fontWeight: "bold",
  },
  searchAuthorDiv: {
    paddingLeft: 40,
  }
}));

export default function Filters({ categories, onCategoryChange, authors, onAuthorChange, onYearChange }) {
  const classes = useStyles();
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(100), (val, index) => year - index);
  const {t} = useTranslation('common');

  return (
    <Grid item xs={12} className={classes.filters}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs='auto' lg={2} className={classes.searchAuthorDiv}>
          <InputLabel className={classes.filterLabel} htmlFor="search-year">
            <span>{t("year_of_publication")}</span>
          </InputLabel>
          <NativeSelect onChange={e => onYearChange(e.target.value)} className={classes.searchYear} inputProps={{id: "search-year"}}>
            <option value="">{t("year")}</option>
            {
              years.map((year, index) => {
                return <option key={`year${index}`} value={year}>{year}</option>
              })
            }
          </NativeSelect>
        </Grid>
        <Grid item xs='auto' lg={2} className={classes.searchAuthorDiv}>
          <InputLabel className={classes.filterLabel} htmlFor="search-year">
            <span>{t("author")}</span>
          </InputLabel>
          <NativeSelect onChange={e => onAuthorChange(e.target.value)} className={classes.searchYear} inputProps={{id: "search-year"}}>
            <option value="">{t("author")}</option>
            {
              authors.map(author => {
                return (<option key={author.id} value={author.id}>{author.name}</option>);
              })
            }
          </NativeSelect>
        </Grid>
        <div className={classes.searchAuthorDiv}>
          <InputLabel className={classes.filterLabel} htmlFor="search-category">
            <span>{t("category")}</span>
          </InputLabel>
          <NativeSelect onChange={e => onCategoryChange(e.target.value)} className={classes.searchYear} inputProps={{id: "search-category"}}>
            <option value="">{t("category")}</option>
            {
              categories.map(category => {
                return (<option key={category.id} value={category.id}>{category.title}</option>);
              })
            }
          </NativeSelect>
        </div>
      </Grid>
    </Grid>
  );
}
