import React from "react"
import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "70px",
  },
  image: {
    width: "100%",
    boxShadow: "0 0 7px 0px #0000008c",
  },
  title: {
    display: "inline-block",
    width: "100%",
    color: "#A7A9AC",
    fontWeight: 700,
    fontSize: 15,
    padding: "10px 0 0 0",
    textTransform: "uppercase",
  },
}));

export default function Gallery({gallery})
{
  const classes = useStyles();
  let { lang } = useParams();

  return (
    <Grid container direction="row" justifyContent="center" className={classes.root}>
      <Grid item xs={11}>
        <Link to={`/${lang}/gallery/${gallery.slug}`}>
          <img alt="" className={classes.image} src={gallery.image_link} />
        </Link>
      </Grid>
      <Grid item xs={11}>
        <div className={classes.titleDiv}>
          <Link className={classes.title} to={`/${lang}/gallery/${gallery.slug}`}>{gallery.title}</Link>
        </div>
      </Grid>
    </Grid>
  );
}
