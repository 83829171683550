import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles, useTheme } from "@mui/styles";
import { Ajax } from "helpers";
import Loader from "../../Partials/Loader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import ShowGallery from "../../Gallery/ShowGallery";
import useMediaQuery from '@mui/material/useMediaQuery';
import NotFound from "../../Errors/NotFound";
import Error from "../../Errors/Error";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 70,
  },
  title: {
    paddingLeft: 15,
    marginBottom: 15,
    fontSize: 22,
    color: "#565a5c",
    display: "inline-block",
    textTransform: "uppercase",
    borderLeft: "2px solid #89868b",
    "& p": {
      margin: 0,
    },
    "& span": {
      fontSize: 16,
      fontFamily: "Arian-AMU",
      display: "inline-block",
      width: "100%",
      marginBottom: 0,
    }
  },
  tabs: {
    borderLeft: "12px solid #bbb9bd",
    borderBottom: "2px dotted #BBB8BC",
    paddingLeft: 15,
    color: "#bbb9bd",
    "& button": {
      borderRight: "2px solid #ccc",
    }
  },
  image: {
    width: "100%"
  },
}));

export default function ShowService() {
  const {t} = useTranslation('common');
  let { slug, lang } = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [service, setService] = useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentGalleryId, setCurrentGalleryId] = useState(null);
  const [gallery, setGallery] = useState(null);
  let galleries = [];

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/api/projects/" + slug, lang)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setError(<NotFound/>);
          setIsLoaded(true);
        } else {
          setError(<Error/>);
          setIsLoaded(true);
        }
      })
      .then(
        (result) => {
          if (result === undefined) {
            return;
          }
          setService(result);
          setGallery(result.gallery);
          if (result.gallery !== null) {
            setCurrentGalleryId(result.gallery.id);
          }
          setIsLoaded(true);
          setCurrentGalleryId(result.gallery.id);
        }
      )
  }, [slug, lang])

  if (!isLoaded) {
    return (
      <Loader/>
    );
  }

  if (error) {
    return error;
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="center" className={classes.root}>
        <Grid item xs={12}>
          <div className={classes.title}>
            <p>{service.title}</p>
            <span>{service.short_description}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <img alt="" src={service.image} className={classes.image}/>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            className={classes.tabs}
            TabIndicatorProps={{ children: <span /> }}
            orientation={isMobile ? "vertical" : "horizontal"}
            onChange={(e, newValue) => {
              setTabIndex(newValue);
              if (newValue !== 0 && galleries[newValue] !== undefined) {
                setGallery(galleries[newValue]);
              } else {
                setGallery(service.gallery);
              }
            }}
            value={tabIndex}
            variant="fullWidth"
          >
            <Tab value={0} disableRipple label={t("about_project")}/>
            {
              service.children.map(page => (
                <Tab value={page.id} key={page.id} disableRipple label={page.title}/>
              ))
            }
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <div hidden={tabIndex !== 0} dangerouslySetInnerHTML={{__html: service.body}}/>
          {
            service.children.map(page => {
              if (page.gallery !== null && currentGalleryId !== page.gallery.id) {
                galleries[page.id] = page.gallery;
              }
              return (<div hidden={tabIndex !== page.id} key={page.id} dangerouslySetInnerHTML={{ __html: page.body }}/>)
            })
          }
        </Grid>
      </Grid>
      {gallery != null && <ShowGallery galleryParam={gallery}/>}
    </Container>
  );
}
