import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Arian-AMU",
    textTransform: "uppercase",
    height: 40,
    borderLeft: "12px solid #D3D0D3",
    paddingLeft: 23,
    paddingTop: 11,
    display: "inline-block",
    width: "100%",
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
  },
  description: {
    display: "block",
    width: "100%",
    margin: "0 auto",
    textAlign: "left",
    boxSizing: "border-box",
    padding: "0 30px",
    "& > p": {
      lineHeight: "23px",
      fontSize: 17,
      color: "#96926E",
      marginBottom: 23,
    },
  },
  readMoreFirst: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 64,
  },
  moreLink: {
    fontSize: 17,
    textDecoration: "none",
    color: "#C1BEC2",
    "& > span": {
      paddingRight: 77,
    },
    "& > svg": {
      verticalAlign: "middle",
      fontSize: 40,
    },
  },
}));

export default function PageBlock({ page, lang }) {
  const classes = useStyles();
  const {t} = useTranslation('common');

  if (page === null || page === undefined) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <div>
          <h1 className={classes.title}>{page.title}</h1>
        </div>
        <div className={classes.description}>
          <p>{page.body}</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.readMoreFirst}>
          <Link className={classes.moreLink} to={`/${lang}/page/${page.slug}`}>
            <span>{t("more")}</span>
            <ArrowRightIcon/>
          </Link>
        </div>
      </Grid>
    </>
  );
}
