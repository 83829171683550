import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {
  Link,
  useLocation
} from "react-router-dom";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import React, {useState} from "react";
import {isValidUrl} from "../../helpers";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import FooterText from "../../../src/images/footer/footer_text.png";
import { styled } from '@mui/material/styles';
import Divider from "@mui/material/Divider";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Collapse} from "@mui/material";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: "10px",
  },
  second_grid: {
    backgroundColor: "#405662"
  },
  link: {
    padding: 7,
    textAlign: 'center',
    color: "#fff",
    textDecoration: 'none',
    transition: '0.5s',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  iconButton: {
    "& > svg": {
      fontSize: 30,
    },
  },
  menuIcon: {
    color: '#fff',
    fontSize: 23,
    backgroundColor: "#405662",
  },
  menuItem: {
    textAlign: "center",
    "& > span": {
      fontSize: 22,
    }
  },
  menu: {
    width: "100%",
  }
}));

export default function Languages({menu = []}) {
  const classes = useStyles();
  const {pathname} = useLocation();
  let newLocation = pathname.substring(3);
  const params = new URLSearchParams(useLocation().search).toString();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const lang = pathname.substring(1, 3);
  const [resourceOpen, setResourceOpen] = useState(false);
  const {t} = useTranslation('common');

  if (params.length > 1) {
    newLocation += '?' + params;
  }

  function getUrl(string) {
    if (isValidUrl(string)) {
      return string;
    }

    return `/${lang}/${string}`;
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  function menuItem(item) {
    if (item.children.length === 0) {
      return (
        <ListItem key={item.id}>
          <ListItemButton component={Link} target={item.target} to={getUrl(item.url)} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <ListItemText primary={item.title} className={classes.menuItem}/>
          </ListItemButton>
        </ListItem>
      );
    }

    return (
      <div key={item.id}>
        <ListItem key={item.id}>
          <ListItemButton onClick={() => setResourceOpen(!resourceOpen)}>
            <ListItemText primary={item.title} className={classes.menuItem}/>
            {resourceOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={resourceOpen} timeout="auto" unmountOnExit>
          <Divider/>
          <List className={classes.menu}>
            {
              item.children.map(child => {
                return (
                    <ListItem key={child.id}>
                      <ListItemButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)} sx={{pl: 4}} component={Link} target={child.target} to={getUrl(child.url)}>
                        <ListItemText primary={child.title} className={classes.menuItem}/>
                      </ListItemButton>
                    </ListItem>
                )
              })
            }
          </List>
          <Divider/>
        </Collapse>
      </div>
    )
  }

  return (
    <Container>
      <Grid
        className={classes.grid}
        container
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid item>
              <IconButton className={classes.iconButton} component="span" onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>
                <MenuIcon className={classes.menuIcon}/>
              </IconButton>
              <Drawer open={mobileMenuOpen} onClose={() => {setMobileMenuOpen(!mobileMenuOpen)}}>
                <DrawerHeader>
                  <img src={FooterText} alt="Logo"/>
                </DrawerHeader>
                <Divider/>
                <List className={classes.menu}>
                  <ListItem>
                    <ListItemButton component={Link} to={`/${lang}/`}>
                      <ListItemText primary={t('home')} className={classes.menuItem}/>
                    </ListItemButton>
                  </ListItem>
                  {
                    menu.map(item => {
                      return menuItem(item)
                    })
                  }
                </List>
              </Drawer>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item>
          <Grid
            className={classes.second_grid}
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Link to={"/am" + newLocation} className={classes.link}>հայերեն</Link>
            </Grid>
            <Grid item>
              <Link to={"/ru" + newLocation} className={classes.link}>русский</Link>
            </Grid>
            <Grid item>
              <Link to={"/en" + newLocation} className={classes.link}>english</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
