import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import YoutubeEmbed from "./YoutubeEmbed";
import { Ajax, setPageTitle } from "helpers";
import Loader from "../../Partials/Loader";
import NotFound from "../../Errors/NotFound";
import Error from "../../Errors/Error";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    minHeight: 400,
  },
  readMore: {
    textAlign: "right",
    borderBottom: "2px dotted #BBB8BC",
    marginBottom: 40,
  },
  subheader: {
    width: "70%",
    textAlign: "left",
    fontSize: 28,
    borderLeft: "12px solid #D3D0D3",
    margin: 0,
    float: "left",
    color: "#C1BEC2",
    fontWeight: 500,
    padding: "4px 0 1px 23px",
  },
  contentBody: {
    textAlign: "justify",
    fontSize: 15,
  },
  author: {
    display: "inline-block",
    width: "100%",
    fontSize: 16,
    marginTop: 9,
    position: "relative",
    borderLeft: "2px solid #ccc",
    paddingLeft: 0,
    "& a": {
      display: "inline-block",
      width: "100%",
      fontSize: 14,
      fontFamily: "MoshVerdana_Bold",
      color: "#000",
      marginTop: 6,
      paddingLeft: 9,
    }
  },
  mainContent: {
    padding: "0 15px 0 15px",
    "& > p": {
      display: "inline-block",
      paddingLeft: 50,
      color: "#565a5c",
      width: "100%",
      fontSize: 18,
      textTransform: "uppercase",
      margin: "0 0 10px 0",
    },
    "& > div": {
      // display: "inline-block",
      width: "100%",
      paddingLeft: 50,
      // boxSizing: "border-box",
    }
  },
}));

export default function ShowVideo() {
  let {slug, lang} = useParams();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    Ajax(process.env.REACT_APP_BASE_URL + "/api/video/" + slug, lang)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          setError(<NotFound/>);
          setIsLoaded(true);
        } else {
          setError(<Error/>);
          setIsLoaded(true);
        }
      })
      .then((result) => {
        if (result === undefined) {
          return;
        }
        setVideo(result.data);
        setIsLoaded(true);
        setPageTitle(result.data.title)
      })
  }, [slug, lang])

  if (!isLoaded) {
    return (<Loader/>);
  }

  if (error) {
    return error;
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid className={classes.readMore} container justifyContent="space-between">
            <Grid item>
              <Link to={`/${lang}/category/video`}>
                <h2 className={classes.subheader}>videos</h2>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid className={classes.contentBody} container justifyContent="center" alignItems="flex-start">
            <Grid item xs={10} className={classes.mainContent}>
              <YoutubeEmbed url={video.video_link} title={video.title}/>
              <p>{video.title}</p>
              <div dangerouslySetInnerHTML={{__html: video.short_description}}/>
              <div className={classes.contentBody} dangerouslySetInnerHTML={{__html: video.body}}/>
            </Grid>
            <Grid item xs={2} className={classes.author}>
              {
                video.authors.map(author => {
                  return (<Link to={`/${lang}/author/${author.slug}`} key={author.id}>{author.name}</Link>);
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
